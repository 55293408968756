import * as React from "react";
import { Route, Routes } from 'react-router-dom'

// import SampleTable from "./components/sample-table/sample-table";
// import ProtectedRoutes from "./middleware/protected-routes";

import Layouts from "./layouts/layouts";
import Login from './containers/login/login'
import Dashboard from './containers/dashboard/dashboard'

function App() {
  return (
      <Routes>
        <Route path="/" element={<Layouts />}>
          <Route path="/" element={<Login/>} />
          <Route path="/dashboard" element={<Dashboard/>} />
        
        </Route>
      </Routes>
  );
}

export default App;
