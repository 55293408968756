import React from "react";
import "./login.css";

import {
  Box,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";



const Login = () => {
  const [values, setValues] = React.useState({
    email: '',
    password: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    delete values.showPassword
    console.log(values)
  }

  const card = (
    <React.Fragment>
      <CardContent className="login__card__content">
        <Typography
          variant="h5"
          component="div"
          className="login__typography__h5"
        >
          Sign in
        </Typography>
  
        <Box
          component="form"
          sx={{
            "& > :not(style)": { width: "40ch" },
          }}
          noValidate
          autoComplete="off"
          su
        >
          <div className="login__input__email">
            <TextField
              id="user-email"
              label="Email"
              variant="outlined"
              fullWidth
              size="small"
              type="email"
              onChange={handleChange("email")}
              value={values.email}
            />
          </div>
  
          <div className="login__input__email">
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </div>
        </Box>
      </CardContent>
      <CardActions>
        <Button size="small" fullWidth variant="contained" type="submit" onClick={handleSubmit}>Sign In</Button>
      </CardActions>
    </React.Fragment>
  );

  return (
    <div className="login__div">
      <Box sx={{ minWidth: 400 }}>
        <Card variant="outlined">{card}</Card>
      </Box>
    </div>
  );
};

export default Login;
