import React from "react";
import "./styles.css";

import { Outlet } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { red, lightBlue } from "@mui/material/colors";

import {
  FormGroup,
  FormControlLabel,
  Switch,
  Container,
  CssBaseline,
} from "@mui/material";

import { Navbar } from "../components/header/navbar/navbar";
// import { SideDrawer } from "../components/header/drawer/drawer";

const Layouts = ({ children }) => {
  const [dark, setDark] = React.useState(false);
  const theme = createTheme({
    palette: {
      mode: dark ? "dark" : "light",
      primary: {
        main: lightBlue[800],
      },
      secondary: {
        main: red[500],
      },
    },
  });

  const handleChange = (event) => {
    setDark(event.target.checked);
  };

  return (
    <ThemeProvider theme={theme}>
      {/* <Paper> */}
      <Container maxWidth="md">
        <div className="layout__root">
          <div style={{ marginTop: "3.8em" }}>
            {/* App Bar */}
            <Navbar />

            {/* Drawer */}
            {/* <SideDrawer /> */}
          </div>

          {/* Main */}
          <CssBaseline />
          <main>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    onChange={handleChange}
                    color="primary"
                    inputProps={{ "aria-label": "controlled" }}
                    checked={dark}
                  />
                }
                label={dark ? "Dark" : "Light"}
              />
            </FormGroup>
            <div style={{ margin: "1em" }}>
              <Outlet />
            </div>
          </main>
        </div>
      </Container>
      {/* </Paper> */}
    </ThemeProvider>
  );
};

export default Layouts;
